.buttons {
  font-size: 18px;
  background-color: #099721;
  color: #fff;
  padding: 10px 20px;
  border: 0px;
  border-radius: 30px;
  margin-bottom: 30px;
  transition: 0.2s;
  opacity: 0.7;
}

.buttons:hover {
  opacity: 1;
}
