input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.form {
  background-color: #181818;
  padding: 25px 35px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 715px;
  margin-bottom: 30px;
  &__datablock {
    margin-bottom: 15px;
  }

  &__datablock h4 {
    margin-bottom: 15px;
  }

  &__timeblock {
    margin-bottom: 15px;
  }

  &__timeblock h4 {
    margin-bottom: 15px;
  }

  &__quantityblock {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;

    &__select {
      text-align: start;
      margin-bottom: 15px;
      font-size: 15px;
    }

    &__input {
      color: #000000;
      border-radius: 8px;
      border: 0px;
      margin-bottom: 25px;
      font-size: 44px;
      height: 80px;
      width: 80px;
      text-align: center;
    }

    &__btn1 {
      color: #ffffff;
      background-color: #349fdd;
      font-size: 18px;
      margin-right: 15px;
      border-radius: 8px;
      border: 0px;
      cursor: pointer;
      font-size: 44px;
      height: 80px;
      width: 80px;
      transition: 0.1s;
    }

    &__btn1:hover {
      background-color: #2774a1;
    }

    &__btn2 {
      color: #ffffff;
      background-color: #349fdd;
      font-size: 18px;
      margin-left: 15px;
      border-radius: 8px;
      border: 0px;
      cursor: pointer;
      font-size: 44px;
      height: 80px;
      width: 80px;
      transition: 0.1s;
    }

    &__btn2:hover {
      background-color: #2774a1;
    }
  }

  &__quantityblock h4 {
    margin-bottom: 15px;
    font-size: 15px;
  }

  &__btn {
    color: #ffffff;
    background-color: #099721;
    padding: 10px;
    border: 0px;
    border-radius: 12px;
    cursor: pointer;
    height: 80px;
    width: 270px;
    font-size: 24px;
    transition: 0.1s;
  }

  &__btn:hover {
    background-color: #037015;
  }
}

// стилизация радиокнопок инпута

.form_radio_btn {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.form_radio_btn input[type='radio'] {
  display: none;
}
.form_radio_btn label {
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-radius: 6px;
  user-select: none;
}

/* Checked */
.form_radio_btn input[type='radio']:checked + label {
  background: #349fdd;
}

/* Hover */
.form_radio_btn label:hover {
  color: #ffffff;
}

/* Disabled */
.form_radio_btn input[type='radio']:disabled + label {
  background: #efefef;
  color: #666;
}

.stockblock {
  display: flex;
  flex-direction: row;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  font-size: 18px;

  &__p {
    margin-left: 5px;
    color: #349fdd;
    margin-right: 5px;
    font-size: 155px;
    font-weight: 700;
  }

  &__loading {
    color: #16b438;
  }
}

.centering {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &__rightblock {
    min-width: 50%;
  }
}

.price {
  text-align: start;
  margin-top: 25px;
  margin-bottom: 60px;
  font-size: 19px;
}

.popup {
  text-align: center;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  opacity: 0.95;
  box-shadow: 0px 0px 10px #349fdd;
  width: 500px;
  height: 200px;
  border-radius: 30px;
  left: 50%;
  top: 50%;
  font-size: 13px;
  transform: translate(-50%, -50%);
  &__icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    opacity: 0.5;
    transition: 0.1s;
  }
  &__icon:hover {
    opacity: 1;
  }
}

@media (max-width: 1280px) {
  .form {
    padding: 20px 29px;
    width: 595px;

    &__datablock {
      margin-bottom: 12.5px;
    }

    &__datablock h4 {
      margin-bottom: 12.5px;
    }

    &__timeblock {
      margin-bottom: 12.5px;
    }

    &__timeblock h4 {
      margin-bottom: 12.5px;
    }

    &__quantityblock {
      margin-bottom: 12.5px;

      &__input {
        border-radius: 8px;
        margin-bottom: 20px;
        font-size: 36px;
        height: 66px;
        width: 66px;
      }

      &__btn1 {
        font-size: 15px;
        margin-right: 12.5px;
        font-size: 36px;
        height: 66px;
        width: 66px;
      }

      &__btn2 {
        font-size: 15px;
        margin-left: 12.5px;
        font-size: 36px;
        height: 66px;
        width: 66px;
      }
    }

    &__quantityblock h4 {
      margin-bottom: 12.5px;
      font-size: 12.5px;
    }

    &__btn {
      padding: 8px;
      height: 66px;
      width: 225px;
      font-size: 20px;
      transition: 0.1s;
    }
  }

  // стилизация радиокнопок инпута

  .form_radio_btn {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .form_radio_btn label {
    padding: 0px 12.5px;
    line-height: 28px;
  }

  .stockblock {
    margin-bottom: 12.5px;
    font-size: 15px;

    &__p {
      margin-left: 4px;
      margin-right: 4px;
      font-size: 129px;
    }
  }

  .centering {
    &__rightblock {
      min-width: 50%;
    }
  }

  .price {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 15px;
  }
}

@media (max-width: 800px) {
  .form {
    padding: 20px 29px;
    width: 450px;
  }

  .popup {
    width: 300px;
    height: 150px;
    left: 50%;
    top: 50%;
    font-size: 12px;
    transform: translate(-50%, -50%);
    &__icon {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      opacity: 0.5;
      transition: 0.1s;
    }
    &__icon:hover {
      opacity: 1;
    }
  }
}

@media (max-width: 450px) {
  .form {
    padding: 20px 29px;
    width: 350px;

    &__btn {
      width: 290px;
      height: 50px;
    }

    &__quantityblock {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__select {
        transform: translateX(-33px);
      }

      &__input {
        width: 135px;
      }
    }
  }

  .centering {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__rightblock {
      min-width: 50%;
    }
  }

  .stockblock {
    margin-top: 30px;
  }
}
