.onpen__menu {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 20px;
    transition: 0.2s;
}

.onpen__menu:hover {
    background-color: rgb(219, 219, 219);
}

.btn__description_uncomplete {
    margin-top: 10px;
    color: rgb(23, 134, 245);
    background: none;
    padding: 0.5em;
    border: 1px solid rgb(23, 134, 245);
    border-radius: 5px;
    transition: 0.2s;
    display: flex;
    justify-content: center;
}

.btn__description_uncomplete:hover {
    background-color: rgba(15, 111, 207, 0.082);
}

.btn__description_complete {
    margin-top: 10px;
    color: rgb(1, 197, 33);
    background: none;
    padding: 0.5em;
    border: 1px solid rgb(1, 197, 33);
    border-radius: 5px;
    transition: 0.2s;
}

.btn__description_complete:hover {
    background-color: rgba(15, 111, 207, 0.082);
}

.seo__link {
    display: flex;
    justify-content: center;
}

.btn__color_uncomplete {
    margin-top: 10px;
    color: rgb(23, 134, 245);
    background: none;
    padding: 0.5em;
    border: 1px solid rgb(23, 134, 245);
    border-radius: 5px;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    margin-right: -7px;
}

.btn__color_uncomplete:hover {
    background-color: rgba(15, 111, 207, 0.082);
}

.btn__color_complete {
    margin-top: 10px;
    color: rgb(1, 197, 33);
    background: none;
    padding: 0.5em;
    border: 1px solid rgb(1, 197, 33);
    border-radius: 5px;
    transition: 0.2s;
    margin-right: -7px;
}

.btn__color_complete:hover {
    background-color: rgba(15, 111, 207, 0.082);
}
