.from__container {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
}

.input__form {
    background-color: #ffffff;
    border-radius: 5px;
    border: none;
}

.btn__delete {
    background: "none";
    background-color: #181818;
    border-radius: 16px;
    color: white;
    border: none;
    display: flex;
    position: relative;
    top: -88px;
    left: 111px;
    transition: 0.2s;
}

.btn__delete_off {
    display: flex;
    background: "none";
    background-color: #181818;
    border-radius: 16px;
    color: rgb(133, 133, 133);
    border: none;
    display: flex;
    position: relative;
    top: -88px;
    left: 111px;
    transition: 0.2s;
}

.btn__delete:hover {
    color: #ff0000;
}

.btn__buy {
    color: #ffffff;
    background-color: #099721;
    padding: 10px;
    border: 0px;
    border-radius: 12px;
    cursor: pointer;
    height: 80px;
    width: 270px;
    font-size: 24px;
    transition: 0.1s;
}

.class__test1 {
    grid-template-columns: repeat(2, 1fr);
    grid-column: 2;
}

.class__test2 {
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 800px) {
    .from__container {
        grid-template-columns: repeat(1, 1fr);
    }

    .class__test1 {
        grid-template-columns: repeat(1, 1fr);
        grid-column: 1;
    }
    
    .class__test2 {
        grid-template-columns: repeat(1, 1fr);
    }
}