
.inputField {
  display: flex;
  flex-direction: column;
  outline: none;

  & > input {
    outline: none;
    border: 0.5px solid rgb(189, 189, 189);
    border-radius: 10px;
    padding: 10px;
  }

  &__error > input {
    border: 1px solid rgb(218, 0, 0);
  }
}

.error {
  margin: 5px 0 0 10px !important;
  font-size: 10px;
  font-weight: bold;
  color: rgb(218, 0, 0);
}
