.page {
  display: flex;
  flex-direction: column;
  width: 1300px;
  margin: auto;
}

.centering {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 210px;

  &__cards {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

@media (max-width: 1335px) {
  .page {
    width: 870px;
  }

  .centering {
    &__cards {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
    }
  }
}

@media (max-width: 880px) {
  .page {
    display: flex;
    flex-direction: column;
    width: 580px;
    margin: auto;
  }

  .centering {
    &__cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 600px) {
  .page {
    display: flex;
    flex-direction: column;
    width: 375px;
    margin: auto;
  }

  .centering {
    &__cards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
