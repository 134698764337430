.page {
  display: flex;
  flex-direction: column;
  width: 1300px;
  margin: auto;
}

select{
  background: none;
  border: none;
  option{
    background-color: black;
    //border: none;
  }
}

.header {
  margin: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__leftblock {
    height: 36px;
    width: 300px;

    &__img {
      height: 36px;
      transition: 0.2s;
    }

    &__img:hover {
      transform: scale(1.03);
    }
  }

  &__rightblock {
    line-height: 36px;
    align-items: center;
    justify-content: center;
    width: 200px;

    &__select {
      vertical-align: middle;

      &__linkselect{
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 1335px) {
  .page {
    width: 870px;
  }

  .header {
    margin: 20px;
    &__leftblock {
      height: 24px;
      width: 200px;
      &__img {
        height: 24px;
      }
    }
 
    &__rightblock {
      line-height: 24px;
      width: 200px;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 880px) {
  .page {
    display: flex;
    flex-direction: column;
    width: 580px;
    margin: auto;
  }
}

@media (max-width: 600px) {
  .page {
    display: flex;
    flex-direction: column;
    width: 375px;
    margin: auto;
  }
}
