.admin__panels {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.add__other__event {
  grid-column: 1;
  border: 1px solid #000000;
  height: 722px;
  width: 314px;
  margin-left: 10px;
}

.add__seo__event {
  grid-column: 5;
  border: 1px solid #000000;
  height: 145px;
  width: 242px;
  margin-right: 10px;
}

.name__of__event {
  grid-column: 3;
  grid-row: 1/2;
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid #000000;
  padding: 10px;
}

.add__text {
  grid-column: 3;
}

.admin__panels {
  background-color: #ffffff;
}

.add__color__button {
  /* display: flex; */
  /* justify-content: center; */
  margin-bottom: 30px;
}

.add__gallery {
  grid-row: 2;
  grid-column: 3;
}

.add__upload {
  margin-top: 10px;
  margin-bottom: -10px;
}

.title__upload {
  margin-bottom: -17px;
  color: #424242;
}

.upload__file {
  margin-bottom: 15px;
}

.border__line__upload {
  margin-top: 15px;
  margin-bottom: 10px;
}

.btn__send__admin__form {
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 5px;
  background: none;
  border: 1px solid #000000;
  grid-column: 3;
  grid-row: 3;
  transition: 0.2s;
  color: rgb(23, 134, 245);
  border: 1px solid rgb(23, 134, 245);

}

.btn__send__admin__form:hover {
  background-color: rgba(140, 201, 255, 0.301);
}

.btn__send__admin__form:hover {
  background-color: rgba(15, 111, 207, 0.082);
}

.btn__sended__admin__form {
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 5px;
  background: none;
  border: 1px solid #000000;
  grid-column: 3;
  grid-row: 3;
  transition: 0.2s;
  color: rgb(23, 245, 138);
  border: 1px solid rgb(23, 245, 138);
}

.btn__sended__admin__form:hover {
  background-color: rgba(140, 201, 255, 0.301);
}

.btn__sended__admin__form:hover {
  background-color: rgba(15, 111, 207, 0.082);
}