@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;500&display=swap');

.footercontainer {
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #0f0f0f;
  height: 280px;
}

.a__link {
  text-decoration: none;
  color: #dfdfdf;
}

.a__link:hover {
  text-decoration: underline;
  color: #dfdfdf;
}

.footer {
  // background-color: #5f5f5f;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  position: relative;
  font-size: 16px;
  margin-bottom: 35px;
  transform: translateX(50px);

  &__title {
    font-size: 14px;
    color: #dfdfdf;
    margin-bottom: 25px;
    font-family: 'Roboto', Sans-serif;
    font-weight: 600;
  }

  &__description {
    color: #abacae;
    line-height: 1.5;
    font-family: 'Roboto Slab', Sans-serif;
  }

  &__leftblock {
    width: 258px;
    font-size: 12px;
  }

  &__centerblock {
    width: 555px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 12px;
  }

  &__rightblock {
    width: 300px;
    font-size: 12px;
  }
}

.bottomfooter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__img {
    height: 30px;
    margin-bottom: 15px;
  }

  &__p {
    color: #656565;
    font-size: 10px;
    font-family: 'Roboto Slab', Sans-serif;
  }
}

@media (max-width: 1335px) {
  .footercontainer {
    height: 200px;
  }
  .footer {
    margin-bottom: 23px;
    transform: translateX(22px);

    &__title {
      font-size: 12px;
      margin-bottom: 17px;
      font-weight: 600;
    }

    &__leftblock {
      width: 200px;
      font-size: 10px;
    }

    &__centerblock {
      width: 420px;
      margin-left: 20px;
      margin-right: 20px;
      font-size: 10px;
    }

    &__rightblock {
      width: 200px;
      font-size: 10px;
    }
  }

  .bottomfooter {
    &__img {
      height: 20px;
      margin-bottom: 10px;
    }
    &__p {
      font-size: 8px;
    }
  }
}

@media (max-width: 880px) {
  .footercontainer {
    height: 590px;
  }

  .footer {
    flex-direction: column;
    transform: translateX(0px);

    &__leftblock {
      width: 300px;
      text-align: center;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 30px;
    }

    &__centerblock {
      width: 300px;
      text-align: center;
      margin-left: 0px;
      margin-right: 0px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 30px;
    }

    &__rightblock {
      width: 300px;
      text-align: center;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 30px;
    }
  }
}
