input::file-selector-button {
    color: rgb(23, 134, 245);
    background: none;
    padding: 0.5em;
    border: 1px solid rgb(23, 134, 245);
    border-radius: 5px;
    transition: 0.2s;
}

input {
    color: rgb(34, 35, 36);
    font-family: Arial;
    width: 500px;
}

input::file-selector-button:hover {
    background-color: rgba(15, 111, 207, 0.082);
}

.btn__uncomplete__photo {
    color: rgb(23, 134, 245);
    background: none;
    padding: 0.5em;
    border: 1px solid rgb(23, 134, 245);
    border-radius: 5px;
    transition: 0.2s;
}

.btn__uncomplete__photo:hover {
    background-color: rgba(15, 111, 207, 0.082);
}

.btn__complete__photo {
    color: rgb(1, 197, 33);
    background: none;
    padding: 0.5em;
    border: 1px solid rgb(1, 197, 33);
    border-radius: 5px;
    transition: 0.2s;
}

.btn__complete__photo:hover {
    background-color: rgba(15, 111, 207, 0.082);
}


/* Load img */
.container__photo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px 48px;
}

.load__btn__container {
    position: relative;
}

.btn__photo__complete {
    position: absolute;
    left: -97px;
}

.btn__photo__uncomplete {
    position: absolute;
    left: -92px;
}


.img__container {
    position: relative;
}

.btn__img__container {
    position: absolute;
    top: 0px;
    left: 174px;
}

.btn__delete__img {
    display: flex;
    align-items: center;
    background: none;
    border: 1px solid #000000;
    background-color: #393939;
    color: #ffffffd8;
}

.btn__delete__img:hover {
    background-color: #454545;
    color: #ffffff;
    transition: 0.2s;
}


.container__image {
    display: flex;
}