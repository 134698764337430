//@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

// * {
//   font-family: 'Comfortaa', sans-serif;
//   color: #fff;
// }
body {
  background-color: #000000;
}

.img {
  fill: #ffb600;
}

.vozrast6{
  color: #ffcc00;
}

.textTitle{
  color: #ffcc00;
  font-weight: 1000;
}


.concertProgName{
  text-align: center;
  font-size: 22px;
}

.video{
  width: 1110px;
  height: 624px;
}

.concertProg{
  color: white;
}

.program{
  color: red;
}

.artistblock{
  text-align: center;
  padding-top: 50px;
}
.artistblockDesc{
  text-align: center;
}

h1{
  font-size: 30px;
}

h2{
  font-size: 24px;
}

h3{
  font-size: 20px;
}

  /* Задаем стиль маркера списка */
ul li{
    list-style-type: disc; /* Тип маркера - кружок */
    color: A9A9A9;
    margin-left: 20px;
    padding-bottom: 15px;
}

// ul li {
//   list-style-type: circle; /* Тип маркера - кружок */
//   color: rgb(44, 44, 44);
// }



.background {
  position: relative;
  width: 200px;

  &__image {
    position: absolute;
    display: block;
    z-index: -10;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0px 0px 30px 30px;
    height: 800px;
    max-width: 100vw;

    object-fit: cover;
    opacity: 1;
  }
}

.headerEvent {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  color: #fff;
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  &__logo {
    margin-top: 500px;
    width: 400px;
    margin-bottom: 50px;
  }
  &__logosport {
    margin-top: 650px;
    margin-bottom: 50px;
  }
}

.vozrast{
  font-size: 15px;
  color: #A9A9A9;
}

.textblock {
  margin-top: 30px;
  margin-bottom: 35px;
  width: 700px;
  &__title {
    font-size: 24px;
    text-align: center;
  }
  &__titlesport{
    font-size: 24px;
    text-align: center;
    color: yellow;
  }
  &__desc {
    margin-top: 40px;
    font-size: 22px;
    text-align: justify;
    line-height: 23pt;
    color: #A9A9A9;
    p{
      margin-bottom: 20px;
    }
  }
  &__desctest{
    margin-top: 40px;
    font-size: 20px;
    text-align: left;
    line-height: 25pt;
  }
}

.iconblock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // margin-top: 80px;
  margin-bottom: 25px;
  gap: 15px;

  &__item {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__img {
      margin-bottom: 20px;
      width: 100px;
    }

    &__title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__p {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
}

.grid-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-bottom: 30px;
}

.grid-item {
  height: 350px;
  width: 350px;
  object-fit: cover;
}

@media (max-width: 1280px) {
  body,
  html {
    overflow-x: hidden;
  }

  h1{
    font-size: 24px;
  }
  
  h2{
    font-size: 20px;
  }
  
  h3{
    font-size: 16px;
  }

  .header {
    font-size: 12px;
    margin-top: 20px;
  }

  .main {
    &__logo {
      margin-top: 350px;
      width: 400px;
    }
    &__logosport {
      margin-top: 350px;
    }

  }

  .background {
    &__image {
      height: 600px;
    }
  }
  .backgroundsport {
    &__image {
      height: 600px;
      margin-top: 100px;
    }
  }

  .grid-item {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 800px) {
  body,
  html {
    overflow-x: hidden;
  }

  // h1{
  //   font-size: 20px;
  // }
  
  // h2{
  //   font-size: 16px;
  // }
  
  // h3{
  //   font-size: 12px;
  // }


  .header{
    font-size: 12px;
    margin-top: 20px;
  }

  .artistblock{
    text-align: center;
    font-size: 20px;
    padding-top: 50px;
  }
  .artistblockDesc{
    text-align: center;
  }
  .video{
    width: 360px;
    height: 240px;
  }

  .vozrast{
    font-size: 15px;
    color: #A9A9A9;
  }

  .textblock {
    margin-top: 30px;
    width: 600px;
    &__title {
      font-size: 28px;
      text-align: center;
      //font-weight: 1000
    }
    &__desc {
      margin-top: 40px;
      font-size: 20px;
      text-align: left;
      color: #A9A9A9;
      margin-bottom: 20px;
      line-height: 18pt;
    }
    &__desctest {
      font-size: 15px;
    }
  }

  .main {
    &__logo {
      margin-top: 300px;
      width: 350px;
    }
    &__logosport {
      margin-top: 300px;
    }
  }

  .background {
    &__image {
      height: 600px;
    }
  }

  .backgroundsport {
    &__image {
      height: 230px;
      margin-top: 20px;
      
    }
  }
  

  .grid-item {
    height: 200px;
    width: 200px;
  }
}

@media (max-width: 450px) {
  body,
  html {
    overflow-x: hidden;
  }

  .textblock {
    margin-top: 10px;
    width: 100%;
    padding: 0px 25px;
  }

  .main {
    &__logo {
      margin-top: 300px;
      width: 340px;
    }
    &__logosport {
      margin-top: 300px;
    }

    .rating {
      margin-top: 60px;
    }
  }

  .iconblock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    gap: 5px;

    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__img {
        margin-bottom: 20px;
        width: 80px;
      }

      &__title {
        margin-bottom: 10px;
        font-size: 16px;
      }

      &__p {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }

  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
  }

  .grid-item {
    width: 100%;
    padding: 0px 10px;
  }

  .footerText {
    font-size: 8px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
  }
}

.footerText {
  text-align: center;
  font-size: 12px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 30px;
}
