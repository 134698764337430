@import './StandartReset';
@import './Event.scss';
@import './HomePage.scss';
@import './Buttons.scss';


body {
  background-color: #000000;
  overflow-x:hidden;
  font-family: 'Comfortaa', sans-serif;
  color: #fff;
}
