.block {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 500px;
  border-radius: 30px;
  width: 400px;
  object-fit: cover;
  background-color: #0b0b0b;
  position: relative;

  &__link {
    position: absolute;
    // padding: 7px;
    // border: 1px solid #0b0b0b;
    z-index: -1;
    height: 100%;
    width: 100%;
    border-radius: 30px;
  }

  &__img {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    object-fit: cover;
    transition: 0.4s;
  }

  &__img:hover {
    transform: scale(1.04);
    opacity: 0.7;
  }

  &__price {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #099721;
    padding: 5px;
    font-size: 16px;
    border-radius: 8px;
  }

  &__logo {
    width: 350px;
  }
}

.textarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px 0px;
  gap: 10px;

  &__zal {
    width: 175px;
    height: 38px;
    opacity: 0.8;
    background-color: #0e2d8c;
    padding: 5px 20px;
    font-size: 11px;
    border-radius: 8px;
    text-align: center;
  }

  &__date {
    width: 175px;
    height: 38px;
    opacity: 0.8;
    background-color: rgb(195, 21, 21);
    padding: 5px 20px;
    font-size: 12px;
    border-radius: 8px;
    text-align: center;
  }

  &__location {
    background-color: #0e2d8c;
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 8px;
  }
}

.bth {
  font-size: 16px;
  z-index: 100;
  height: 38px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: none;
  outline: none;
  color: #fff;
  background: #58cc47;
  cursor: pointer;
  width: 360px;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.bth:before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.bth:active {
  color: #fff;
}

.bth:active:after {
  background: transparent;
}

.bth:hover:before {
  opacity: 1;
}

.bth:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #099721;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@media (max-width: 1335px) {
  .block {
    height: 333px;
    border-radius: 20px;
    width: 266px;
    &__link {
      padding: 5px;
      // поправить
      border-radius: 30px;
    }

    &__img {
      border-radius: 30px;
    }

    &__img:hover {
      transform: scale(1.04);
    }

    &__price {
      top: -3px;
      right: -3px;
      padding: 3px;
      font-size: 11px;
    }

    &__logo {
      width: 233px;
    }
  }

  .textarea {
    margin: 0px 0px 0px 0px;
    gap: 10px;

    &__zal {
      width: 116px;
      height: 25px;
      padding: 3px 5px;
      font-size: 8px;
    }
    &__date {
      width: 116px;
      height: 25px;
      padding: 3px 5px;
      font-size: 8px;
    }
  }

  .bth {
    font-size: 11px;
    height: 25px;
    margin-top: 6px;
    margin-bottom: 15px;
    width: 240px;
    border-radius: 10px;
  }
}

@media (max-width: 880px) {
  .block {
    height: 333px;
    border-radius: 20px;
    width: 266px;
    &__link {
      padding: 5px;
      // поправить
      border-radius: 30px;
    }

    &__img {
      border-radius: 30px;
    }

    &__img:hover {
      transform: scale(1.04);
    }

    &__price {
      top: -3px;
      right: -3px;
      padding: 3px;
      font-size: 11px;
    }

    &__logo {
      width: 233px;
    }
  }

  .textarea {
    margin: 0px 0px 0px 0px;
    gap: 10px;

    &__zal {
      width: 116px;
      height: 25px;
      padding: 3px 5px;
      font-size: 8px;
    }
    &__date {
      width: 116px;
      height: 25px;
      padding: 3px 5px;
      font-size: 8px;
    }
  }

  .bth {
    font-size: 11px;
    height: 25px;
    margin-top: 6px;
    margin-bottom: 15px;
    width: 240px;
    border-radius: 10px;
  }
}

@media (max-width: 600px) {
  .block {
    height: 399px;
    border-radius: 24px;
    width: 319px;
    &__link {
      padding: 6px;
      // поправить
      border-radius: 30px;
    }

    &__img {
      border-radius: 30px;
    }

    &__img:hover {
      transform: scale(1.04);
    }

    &__price {
      top: -4px;
      right: -4px;
      padding: 4px;
      font-size: 13px;
    }

    &__logo {
      width: 280px;
    }
  }

  .textarea {
    gap: 10px;
    &__zal {
      width: 153px;
      height: 30px;
      padding: 3px 5px;
      font-size: 10px;
    }
    &__date {
      width: 116px;
      height: 30px;
      padding: 4px 6px;
      font-size: 10px;
    }
  }

  .bth {
    font-size: 13px;
    height: 30px;
    margin-top: 7px;
    margin-bottom: 18px;
    width: 280px;
    border-radius: 12px;
  }
}
